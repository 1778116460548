/*Class App del controller
 * Gestisce tutte le operazioni dell'Applicazione per macrofunzioni (alto livello)
 * Autore:  Fiorelli Luigi
 * Data:    01/09/2017
 * Agg :    09/10/2017
 * V   :    1.1.6
 */
var App = {
    data: {
        CONSOLE_MSG_ACTIVE: false,
        INAPP_MSG_ACTIVE: true,
        INAPP_MSG_SHOWED: false,
        INAPP_MSG: "",
        logged: false, //definisce se si è già fatto il login
        tribListAgg: false, //definisce se è stata aggiornata la lista tributi
        tribList: null,
        ongoingComunication: false, //Definisce se c'è una comunicazione in atto
        firstLogin: true, //Definisce se è la prima volta che si prova a loggarsi
        funStartUser: "",
        funTryReg_ok: "",
        funTryReg_fail: "",
        pageOpened: 1, //Definisce la pagina aperta
        email: "", //Definisce la mail dell'utente
        password: "", //definisce la password in chiaro dell'utente - deprecato, ora è criptata v1.0.0
        nome: "",
        cognome: "",
        telefono: "",
        citta: "",
        ac_pr: "",
        ac_ne: "",
        ac_co: "0",
        DELAY_openPage: 25, //Delay prima che venga aperta effettivamente una pagina
        DELAY_engine: 333,
        DELAY_aggGen: 60000,
        SAVE_NAME_01: "save_01.sav",
        loginTryNumber: 0,
        loginTryNumberMax: 4,
        optionAgg: false, //Se true c'è un opzione aggiornata, necessita save
        tryReg: false //Indica se si vuole provare a registrarsi
    },
    engine: function(){
        if( !App.data.ongoingComunication ) {
            if(!logged()) App.data.logged = false;
            if(App.data.tryReg){
                RAD.req_reg(reg_ok,reg_fail);
            }
            if(App.data.loginTryNumber >= App.data.loginTryNumberMax){
                if(App.data.firstLogin){
                    try{
                        App.data.funStartUser();
                    }catch(err){
                        App.io.log(err);
                    }
                    App.data.firstLogin = false;
                }
            } else {
                if( !App.data.logged ){
                    App.io.log("Logging Try");
                    App.data.loginTryNumber ++;
                    RAD.set_email(App.data.email);
                    RAD.set_password(App.data.password512);
                    App.onGoing();
                    RAD.req_login(log_ok,log_fail); //restituisce un valore true o false. se false richiedere errore con get_error()
                } else {
                    if( !App.data.ongoingComunication){
                        //Scarico lista tributi
                        if( !App.data.tribListAgg && !App.data.ongoingComunication){ 
                            App.io.log("Request Lista Tributi...")
                            App.onGoing(); 
                            RAD.req_trib_list(trib_ok,trib_fail); 
                        }
                        //Scarico la lista delle cartelle
                        //App.io.log("cartelle: ",(!App.page.cartelle.data.cartelleAgg), !App.data.ongoingComunication, (!App.page.cartelle.data.cartelleAgg) && (!App.data.ongoingComunication));
                        if( (!App.page.cartelle.data.cartelleAgg) && (!App.data.ongoingComunication)){ 
                            App.io.log("Request Lista Cartelle");
                            App.onGoing(); 
                            RAD.req_cart_list(cart_list_ok,cart_list_fail);
                        }
                        //Esegue controlli e codice in base alla pagina
                        switch( App.data.pageOpened ){
                            case 1: //Home
                            break;
                            case 2: //Cartelle
                                //App.io.log("list trib: ", !App.page.cartelle.data.tribListShowed && App.data.tribList != ""," - ",App.data.tribList)
                                if(App.data.tribList != null){
                                    if(!App.page.cartelle.data.tribListShowed){
                                        if( trib_show(App.data.tribList) ){
                                            App.page.cartelle.data.tribListShowed = true;
                                            App.io.log("Trib List Show");
                                        } else {
                                            App.io.log("Trib List Show Fail");
                                        }
                                    }
                                } else {
                                    App.io.log("Request Lista Tributi...")
                                    App.onGoing(); 
                                    RAD.req_trib_list(trib_ok,trib_fail); 
                                }
                                //Carico gli anni del tributo
                                if(! App.page.cartelle.data.anniListShowed){
                                    anni_list = document.getElementById('estr_anno');
                                    if(anni_list != null){
                                        var date = new Date();
                                        for( i=1980;i<=date.getFullYear();i++ ) {
                                            anni_list.add( new Option( i,i ) );
                                        };
                                        App.page.cartelle.data.anniListShowed = true;
                                    }
                                }
                                //Mostro le cartelle se sono aggiornate
                                if( (!App.data.ongoingComunication) && App.page.cartelle.data.request_showCart ){
                                    App.io.log("Request Show Cart");
                                    App.page.cartelle.data.request_showCart = false;
                                    try{
                                        App.page.cartelle.data.request_showCart_f(App.page.cartelle.data.cartelle_list);
                                    } catch(err){
                                        
                                    }
                                    
                                }
                            break;
                        }
                    }
                }
            }
        }
        //App.io.log("Engine runtime - ongoing: " + App.data.ongoingComunication);
    },
    onGoing: function(){
        App.data.ongoingComunication = true;
    },
    outGoing: function(){
        App.data.ongoingComunication = false;
    },
    start: function(fun){
        console.log("Se stai leggendo questo significa che stai facendo qualcosa che non dovresti fare...");
        App.io.log("Rimedia App Starting...");
        App.option.load();
        RAD.set_email(App.data.email);
        RAD.set_password(App.data.password);
        window.setInterval("App.engine()",App.data.DELAY_engine); //Starto l'engine
        //window.setInterval("App.fun.aggCart()",App.data.DELAY_aggGen + Math.floor(Math.random() * 5000)); //Aggiorno la lista tributi
        //window.setInterval("App.fun.aggTrib()",App.data.DELAY_aggGen + Math.floor(Math.random() * 5000)); //Aggiorno la lista delle cartelle
        App.page.home.show();
        App.io.log("Rimedia App Started");
        
        try{
            App.data.funStartUser = fun;
        }catch(err){
            App.io.log(err);
        }
    },
    io: {
        setEmail: function(val){
            App.data.email = val;
        },
        setPassword: function(val){
            App.io.setPassword512(sha512(val));
        },
        setPassword512: function(val){
            App.data.password = val;
            App.data.password512 = val;
        },
        setFunLoginFailed: function(fun){
            App.data.funStartUser = fun;
        },
        setNome: function(val){
            App.data.nome = val;
        },
        setCognome: function(val){
            App.data.cognome = val;
        },
        setTelefono: function(val){
            App.data.telefono = val;
        },
        setCitta: function(val){
            App.data.citta = val;
        },
        setAc_pr: function(val){
            if(val==true || val ==1){
                App.data.ac_pr = 1;
            } else {
                App.data.ac_pr = 0;
            }
        },
        setAc_ne: function(val){
            if(val== true || val==1){
                App.data.ac_ne = 1;
            } else {
                App.data.ac_ne = 0;
            }
        },
        setAc_co: function(val){
            if(val==true || val==1){
                App.data.ac_co = 1;
            } else {
                App.data.ac_co = 0;
            }
        },
        getLogged: function(){
            return App.data.logged;
        },
        getEmail: function(){
            return App.data.email;
        },
        getNome: function(){
            return App.data.nome;
        },
        getCognome: function(){
            return App.data.cognome;
        },
        getTelefono: function(){
            return App.data.telefono;
        },
        getCitta: function(){
            return App.data.citta;
        },
        getAc_pr: function(){
            if(App.data.ac_pr == 1){
                return true;
            } else {
                return false;
            }
        },
        getAc_co: function(){
            if(App.data.ac_co == 1){
                return true;
            } else {
                return false;
            }
        },
        getAc_ne: function(){
            if(App.data.ac_ne == 1){
                return true;
            } else {
                return false;
            }
        },
        getConsoleMsg: function(){
            return App.data.INAPP_MSG;
        },
        log: function(msg){
            if(App.data.CONSOLE_MSG_ACTIVE) console.log(msg);
            if(App.data.INAPP_MSG_ACTIVE) App.data.INAPP_MSG += msg + "\n";
        },
    },
    fun: {
        loginNumberZero: function(){
            App.data.loginTryNumber = 0;
        },
        tryReg: function(){
            RAD.data.email = App.data.email;
            RAD.data.password = App.data.password;
            RAD.data.password512 = App.data.password512;
            RAD.data.nome = App.data.nome;
            RAD.data.cognome = App.data.cognome;
            RAD.data.telefono = App.data.telefono;
            RAD.data.citta = App.data.citta;
            RAD.data.ac_co = App.data.ac_co;
            RAD.data.ac_ne = App.data.ac_ne;
            RAD.data.ac_pr = App.data.ac_pr;
            RAD.req_reg(reg_ok,reg_fail);
        },
        aggCart: function(){
            App.page.cartelle.data.cartelleAgg = false;
        },
        aggTrib: function(){
            App.data.tribListAgg = true;
        }
    },
    page: {
        //Page: 1
        home: {
            show: function(){
                App.io.log("Page: Home - 1");
                //App.data.pageOpened = 1;
                window.setTimeout("App.data.pageOpened = 1",200);
            },
            hide:function(){
                
            }
        },
        //Page: 2
        cartelle: {
            data: {
                cartelleAgg: false, //Definisce se le cartelle sono già state aggiornate
                cartelle_list: "", //Contiene la lista delle cartelle
                newCart: "", //Contiene la nuova cartella da spedire al server
                request_showCart : true, //
                request_showCart_f : "", //La funzione da eseguire per portare in output la lista delle cartelle
                tribListShowed : false, //Definisce se la lista dei tributi è stata visualizzata
                anniListShowed : false, //Definisce se la lista degli anni tributo è stata visualizzata
                newCartN : 0,
                newCart_fail: "",
                newCart_ok: ""
            },
            show: function(){
                App.io.log("Page: Cartelle - 2");

                window.setTimeout("App.page.cartelle.show_f()",App.data.DELAY_openPage);
                
                App.page.cartelle.data.tribListShowed = false;
                App.page.cartelle.data.anniListShowed = false;
                App.page.cartelle.data.request_showCart = true;
                App.data.pageOpened = 2;
            },
            hide: function(){

            },
            show_f: function(){
                try{
                    App.page.cartelle.data.request_showCart_f(App.page.cartelle.data.cartelle_list);
                } catch(err){

                }
            },
            setNewCart: function(cart){
                App.page.cartelle.data.newCart = cart;
            },
            b_sendNewCart: function(f_ok,f_fail){
                //Carico i dati
                //var cartella = new Array();
                //trib_list = document.getElementById('elenco_tributi');
                //cartella["id_ttr"] = trib_list.selectedIndex;
                //cartella["tr_anno"] = $("#estr_anno").val();
                //cartella["tr_importo"] = $("#estr_importo").val();
                //cartella["tr_ruolo"] = $("#estr_ruolo").val();
                //cartella["tr_notifica"] = $("#estr_notifica").val();
                //cartella["note"] = $("#estr_note").val();
                //App.page.cartelle.data.newCart = cartella;
                App.page.cartelle.data.newCart_fail = f_fail;
                App.page.cartelle.data.newCart_ok = f_ok;
                App.page.cartelle.data.newCartN = 0;
                error = cart_check_data(App.page.cartelle.data.newCart);
                
                if(!error) {
                    App.page.cartelle.sendNewCart();
                } else {
                    App.io.log("Errore nei dati della cartella - " + error);
                    //Output dell'errore
                }
                
            },
            sendNewCart: function() {
                if(App.data.logged){
                    App.page.cartelle.data.newCartN ++;
                    if(App.page.cartelle.data.newCartN < 7){
                        if(!App.data.ongoingComunication){
                            App.io.log("Request Add Cartella...");
                            App.onGoing(); 
                            window.setTimeout("sendNewCartAppFunction()",10);//Eseguo il codice per richiedere l'aggiunta della cartella
                        } else {
                            window.setTimeout("App.page.cartelle.sendNewCart()",33);

                        }
                    } else {
                        try{
                            App.page.cartelle.data.newCart_fail("Troppi tentativi");
                        } catch(e) {
                            
                        }
                        
                    }
                } else {
                    window.setTimeout("App.page.cartelle.sendNewCart()",150);
                }
                
            },
            setShowCartF(f){
                App.page.cartelle.data.request_showCart_f = f;
            },
            showCart: function(){
                App.page.cartelle.data.request_showCart = true;
            },
            loadCart: function(){
                
            }
        },
        //Page: 3
        chi_siamo:{
            data:{
                
            },
            show: function(){
                App.io.log("Page: Chi Siamo - 3");
                window.setTimeout("App.data.pageOpened = 3",App.data.DELAY_openPage);
            },
            hide: function(){
                
            }
        },
        /*Page: 4*/
        giurisprudenza:{
            data:{
                
            },
            show: function(){
                App.io.log("Page: Giurisprudenza - 4");
                window.setTimeout("App.data.pageOpened = 4",App.data.DELAY_openPage);
            },
            hide: function(){
                
            }
        },
        //Page: 5
        tutorial:{
            data:{
                
            },
            show: function(){
                App.io.log("Page: Tutorial - 5");
                window.setTimeout("App.data.pageOpened = 5",App.data.DELAY_openPage);
            },
            hide: function(){
                
            }
        },
        /*Page: 6*/
        news:{
            data:{
                
            },
            show: function(){
                App.io.log("Page: News - 6");
                window.setTimeout("App.data.pageOpened = 6",App.data.DELAY_openPage);
            },
            hide: function(){
                
            }
        },
        //Page: 7
        contatti:{
            data:{
                f_ok: "",
                f_fail: "",
                new_msg: false,
                title: "",
                msg: ""
            },
            show: function(){
                App.io.log("Page: Contatti - 7");
                window.setTimeout("App.data.pageOpened = 7",App.data.DELAY_openPage);
            },
            hide: function(){
                
            },
            b_sendMsg: function(f_ok,f_fail){
                App.page.contatti.data.f_ok = f_ok;
                App.page.contatti.data.f_fail = f_fail;
                RAD.req_send_msg(f_ok,f_ok,App.page.contatti.data.title,App.page.contatti.data.msg);
            },
            set_msg: function(title,msg){
                App.io.log(title + ": " + msg);
                App.page.contatti.data.msg = msg;
                App.page.contatti.data.title = title;
                //window.setTimeout("App.page.contatti.data.f_fail()",250);
            }
        },
        //Page: 8
        profilo:{
            data:{
                
            },
            show: function(){
                App.io.log("Page: Profilo - 8");
                window.setTimeout("App.data.pageOpened = 8",App.data.DELAY_openPage);
            },
            hide: function(){
                
            },
            b_logout: function(){
                App.io.setPassword512("");
                RAD.data.password = App.data.password;
                RAD.data.password512 = App.data.password512;
                RAD.data.log_code_pre = "";
                App.option.free();
                App.option.save();
                App.option.load();
                //App.io.log("Pw: " + App.option.data.password512);
                App.data.pageOpened = 1;
                App.data.loginTryNumber = App.data.loginTryNumberMax -1;
                App.data.firstLogin = true;
                App.data.logged = false;
                
            },
            b_change_password: function(f_ok,f_fail,pa,p2,p3){
                if(sha512(pa) == App.data.password512 && p2 == p3){
                    RAD.req_change_data_password(f_ok,f_fail,sha512(pa),sha512(p2));
                } else {
                    try{
                        f_fail("C1");
                    } catch(err) {
                        
                    }
                }
                
            },
            b_change_newsLetter: function(f_ok,f_fail,val){
                
            }
        },
        //Page: 9
        login:{
            show: function(){
                App.io.log("Page: Profilo - 9");
                window.setTimeout("App.data.pageOpened = 9",App.data.DELAY_openPage);
            },
            hide: function(){
                
            },
            b_tryLogin: function(){
                App.data.firstLogin = true;
                App.fun.loginNumberZero();
            },
            b_tryReg: function(f_ok,f_fail){
                try{
                    App.data.funTryReg_ok = f_ok;
                    App.data.funTryReg_fail = f_fail;
                } catch(errTry){
                    //
                }
                
                var err = "A0";
                if(App.data.email.length < 4 && check_email(App.data.email)) err = "A1";
                
                if(App.data.password.length > 6){
                    
                } else {
                    err = "A2";
                }
                
                if(App.data.nome.length > 3){
                    
                }else{
                    err = "A4";
                }
                if(App.data.cognome.length > 3){

                }else{
                    err = "A5";
                }
                
                if( !(App.data.ac_pr == 1) ){
                    err = "A3";
                }
                
                if(err != "A0"){
                    try{
                        App.data.funTryReg_fail(err);
                    } catch(errTry){
                        App.io.log(errTry);
                    }
                } else {
                    App.io.log("Send Try Reg");
                    App.fun.tryReg();
                }
            },
            b_recPass: function(email,f_ok,f_fail){
                RAD.set_email(email);
                RAD.req_rep_pass(f_ok,f_fail);
            }
        }
    },
    option: {
        storage: window.localStorage, //Indirizzo dei Cookie per il save
        data: {
            password512: "",
            email: "",
            cartelle_list: "",
            tribList: "",
            nome: "",
            cognome: "",
            telefono: "",
            citta: "",
            ac_ne: 0,
            ac_pr: 0,
            ac_co: 0
        },
        free: function(){
            App.option.data.password512 = "";
            App.option.data.email = "";
            App.option.data.cartelle_list = "";
            //App.option.data.tribList = "";
            App.option.data.nome = "";
            App.option.data.cognome = "";
            App.option.data.telefono = "";
            App.option.data.ac_ne = 0;
            App.option.data.ac_pr = 0;
            App.option.data.ac_co = 0;
        },
        load: function(){
            //App.io.log("Loading Save...");
            
            //Carico i dati del save nella variabile val
            var val = App.option.storage.getItem($.trim(App.data.SAVE_NAME_01));
            
            //Controllo se il file save contiene qualcosa, in caso contrario do errore
            try{
                val = JSON.parse(val);
            }catch(err){
                App.io.log("Save vuoto");
                error = false;
            }
            
            //Copia dei valori di salvataggio
                //Password
                try{
                    if(val.password512 !== null){
                        App.option.data.password512 = val.password512;
                        App.data.password512 = App.option.data.password512;
                        App.data.password = App.option.data.password512;
                    }
                } catch(err){
                    App.option.data.password512 = "";
                    App.data.password = "";
                    App.io.log("No Saved Password");
                }
                //Email
                try{
                    if(val.email !== null){
                        App.option.data.email = val.email;
                        App.data.email = val.email;
                    }
                } catch(err){
                    App.io.log("No Saved Email");
                }
                //Lista Cartelle
                try{
                    if(val.cartelle_list !== null){
                        App.option.data.cartelle_list = val.cartelle_list;
                        App.data.cartelle_list = val.cartelle_list;
                    }
                } catch(err){

                }  
                //Lista Tributi
                try{
                    if(val.tribList !== null){
                        App.option.data.tribList = val.tribList;
                        App.data.tribList = val.tribList;
                    }
                } catch(err){

                }
                //Nome
                try{
                    if(val.nome !== null){
                        App.option.data.nome = val.nome;
                        App.data.nome = val.nome;
                    }
                } catch(err){
                    App.io.log("No Saved Nome");
                }
                //Cognome
                try{
                    if(val.cognome !== null){
                        App.option.data.cognome = val.cognome;
                        App.data.cognome = val.cognome;
                    }
                } catch(err){
                    App.io.log("No Saved Cognome");
                }
                //Telefono
                try{
                    if(val.telefono !== null){
                        App.option.data.telefono = val.telefono;
                        App.data.telefono = val.telefono;
                    }
                } catch(err){
                    App.io.log("No Saved Telefono");
                }
                //Citta
                try{
                    if(val.citta !== null){
                        App.option.data.citta = val.citta;
                        App.data.citta = val.citta;
                    }
                } catch(err){
                    App.io.log("No Saved Città");
                }
                //Privacy Contatto (Deprecated)
                try{
                    if(val.ac_co !== null){
                        App.option.data.ac_co = val.ac_co;
                        App.data.ac_co = val.ac_co;
                    }
                } catch(err){
                    App.io.log("No Saved ac_co");
                }
                //Privacy
                try{
                    if(val.ac_pr !== null){
                        App.option.data.ac_pr = val.ac_pr;
                        App.data.ac_pr = val.ac_pr;
                    }
                } catch(err){
                    App.io.log("No Saved ac_pr");
                }
                //Privacy NewsLetter
                try{
                if(val.ac_ne !== null){
                    App.option.data.ac_ne = val.ac_ne;
                    App.data.ac_ne = val.ac_ne;
                }
            } catch(err){
                App.io.log("No Saved ac_ne");
            }
            
            App.io.log("Loaded Save"); 
            //App.option.save();
        },
        save: function(){
            //App.io.log("Saving...");
            
            //Carico i dati dal'App all'attay di salvataggio
            App.option.data.password512 = App.data.password512;
            App.option.data.email = App.data.email;
            App.option.data.nome = App.data.nome;
            App.option.data.cognome = App.data.cognome;
            App.option.data.telefono = App.data.telefono;
            App.option.data.citta = App.data.citta;
            App.option.data.ac_co = App.data.ac_co;
            App.option.data.ac_ne = App.data.ac_ne;
            App.option.data.ac_pr = App.data.ac_pr;
            App.option.data.cartelle_list = App.data.cartelle_list;
            App.option.data.tribList = App.data.tribList;
            
            //Effettuo il save fisico dei dati
            App.option.storage.setItem(App.data.SAVE_NAME_01, JSON.stringify(App.option.data));
            
            App.io.log("Saved");
        }
    }
};